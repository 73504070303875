import { Box, LinearProgress } from "@mui/material";
import React from "react";
import CellRenderer from "./CellRenderer";
import { TableColumn } from "../../models/Table";

interface DetailPanelProps {
  /**
   * The MRT row data the Detail Panel belongs to
   */
  row: any;
  /**
   * Detail columns data (schema)
   */
  columns: TableColumn[];
  /**
   * Defines if row is in edit mode - renders cell data as a different component
   * (example: Select element instead of a text of the selected value) and defines if value editing is enabled.
   */
  isEditMode: boolean;
  /**
   * Object containing the fields (accessorKey) of the row where validation failed
   * when trying to save changes on an edited or new created row.
   */
  fieldErrors: { [key: string]: boolean };
  /**
   * Callback for onChange events
   * @param rowIndex - index from data set (row) being edited
   * @param columnName - property name of data being edited
   * @param value - new input value for property being edited
   */
  onCellValueChange: (rowIndex: number, columnName: string, value: any) => void;
}
/**
 * Renders the hidden columns of a table row when is expanded.
 */
const DetailPanel: React.FC<DetailPanelProps> = ({
  row,
  columns,
  isEditMode,
  fieldErrors,
  onCellValueChange,
}) => {
  // do not perform any costly render actions when row is not expanded
  if (!row.getIsExpanded()) {
    return null;
  }

  // Show loading feedback while detail fields request is pending
  return row.original[columns[0].accessorKey] === undefined ?
    <LinearProgress />
    :
    <>
      {columns.map((column) => (
        <Box key={column.accessorKey} sx={{ mt: 2, width: "50%" }}>
          <CellRenderer
            fieldErrors={fieldErrors}
            rowIndex={row.id}
            cellData={row.original[column.accessorKey]}
            column={column}
            isEditMode={isEditMode}
            isDetailPanel={true}
            onCellValueChange={onCellValueChange}
          />
        </Box>
      ))}
    </>
};

export default DetailPanel;
