import React, { memo, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SelectCell from "./SelectCell";
import { Box } from "@mui/material";
import { formatDate, formatUtcDateToIsoString, translate } from "../../Common";
import { IOSSwitch } from "../../theme/theme";
import UploadLogoModal from "./UploadLogoModal";
import { useTranslation } from "react-i18next";
import { typography } from "../../theme/typography";
import { TableColumn } from "../../models/Table";


interface CellRendererProps {
    /**
     * Index of the row the data gets rendered to
      */
    rowIndex: number;
    /**
     * The value of the data to be rendered
     */
    cellData: any;
    /**
     * Column data (schema)
     */
    column: TableColumn;
    /**
     * Defines if row is in edit mode - renders cell data as a different component
     * (example: Select element instead of a text of the selected value) and defines if value editing is enabled.
     */
    isEditMode: boolean;
    /**
     * Defines if cell data of the row is rendered only in the expandable detail view.
     * Renders data like in "isEditMode" to also show its according column header.
     */
    isDetailPanel: boolean;
    /**
     * Object containing the fields (accessorKey) of the row where validation failed
     * when trying to save changes on an edited or new created row.
     */
    fieldErrors: { [key: string]: boolean };
    /**
     * Callback for onChange events
     * @param rowIndex - index from data set (row) being edited
     * @param columnName - property name of data being edited
     * @param value - new input value for property being edited
     */
    onCellValueChange: (rowIndex: number, accessorKey: string, value: any) => void;
}

/**
 * Defines custom cell renderers based on a column type from BE schema
 */
const CellRenderer: React.FC<CellRendererProps> = memo(({
                             rowIndex,
                             cellData,
                             column,
                             isEditMode,
                             isDetailPanel,
                             fieldErrors,
                             onCellValueChange,
                           }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleChange = (value) => {
    onCellValueChange(rowIndex, column.accessorKey, value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveLogos = (logos) => {
    handleChange(logos);
  };

  /**
   * Defines the type property for the <input> element.
   * @param schemaType - Type according to BE schema
   */
  const setTypeOfInput = (schemaType: string): string => {
    switch (schemaType) {
      case "Integer":
        return "number";
      case "Float":
        return "number";
      case "Text":
        return "text";
      case "ARRAY":
        return "text";
      case "DateTime":
        return "date";
      default:
        return schemaType; // should throw error on purpose if BE schema adds a new type that FE does not know
    }
  };

  /**
   * Returns comma separated and sorted string.
   * @param text - String array of values
   */
  const mergeAndSortMultiple = (text: string[]): string => {
    return text
      .filter((value) => column.options[value] !== undefined) // Filter out values not present in options
      .map((value) => column.options[value]) // Map values to their corresponding labels
      .sort((a, b) => a.localeCompare(b))
      .join(", ");
  };


  switch (column.type) {
    case "Text":
    case "Integer":
    case "ARRAY":
    case "Float":
      return isEditMode || isDetailPanel ? (
        // expanded row or expanded for edit mode
        <TextField
          disabled={!isEditMode || !column.isEditable}
          fullWidth
          label={column.header}
          variant="standard"
          size="small"
          type={setTypeOfInput(column.type)}
          error={fieldErrors[column.accessorKey]}
          value={cellData ?? ''}
          multiline={setTypeOfInput(column.type) === 'text'}
          maxRows={5}
          onChange={(event) => handleChange(event.target.value)}
          slotProps={{
            htmlInput: { maxLength: column.maxLength ? column.maxLength : undefined }
          }}
        />
      ) : (
        // Columns data when not expanded
        <Typography>{cellData ?? ''}</Typography>
      );

    case "DateTime":
      // expanded row or expanded for edit mode
      return isEditMode || isDetailPanel ? (
        <TextField
          disabled={!isEditMode || !column.isEditable}
          fullWidth
          label={column.header}
          variant="standard"
          size="small"
          // Render DateTime schema types only as HTML Date input when it's editable
          type={column.isEditable ? "date" : "text"}
          error={fieldErrors[column.accessorKey]}
          // Adjust date formatting display to input type "date" or "text": Not editable dates should be rendered with time.
          // Render null dates with empty string to ensure that Text field label does not overlap the default "tt.mm.jjjj" display.
          value={cellData ? (column.isEditable ? formatUtcDateToIsoString(cellData) : formatDate(cellData)) : " "}
          onChange={(event) => handleChange(event.target.value)}
        />
      ) : (
        // Columns data when not expanded: Render date as readable string
        <Typography>{formatDate(cellData)}</Typography>
      );

    case "Enum":
      // expanded row or expanded for edit mode: Render array as Select
      return isEditMode || isDetailPanel ? (
        <SelectCell
          disabled={!isEditMode || !column.isEditable}
          label={column.header}
          value={cellData}
          options={column.options || []}
          error={fieldErrors[column.accessorKey]}
          onChange={(value) => handleChange(value)}
          nullable={column.nullable}
          accessorKey={column.accessorKey}
        />
      ) : (
        // Columns data when not expanded: Render the selected option
        <Typography>{translate(t, column.accessorKey, cellData)}</Typography>
      );

    case "UUID":
      // expanded row or expanded for edit mode: Render Map as Select
      return isEditMode || isDetailPanel ? (
        <SelectCell
          disabled={!isEditMode || !column.isEditable}
          label={column.header}
          value={cellData}
          options={column.options || []}
          error={fieldErrors[column.accessorKey]}
          onChange={(value) => handleChange(value)}
          nullable={column.nullable}
          multiple={column.accessorKey === "user_roles" || column.accessorKey === "client_carriers" || column.accessorKey === "needs_mail_confirmation_to_clients" || column.accessorKey === "needs_mail_confirmation_to_carriers"}
          accessorKey={column.accessorKey}
        />
      ) : (
        // Columns data when not expanded: Render the selected option
        <>
          {column.accessorKey === "user_roles" || column.accessorKey === "client_carriers" || column.accessorKey === "needs_mail_confirmation_to_clients" || column.accessorKey === "needs_mail_confirmation_to_carriers" ? ( // data that has multiple selection
            <Typography>{mergeAndSortMultiple(cellData)}</Typography>
          ) : (
            <Typography>
              {translate(t, column.accessorKey, column.options[cellData])}
            </Typography>
          )}
        </>
      );

    case "JSON":
      // Reserved for client and carrier logo - position in expanded defined via backend response order
      return (
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography sx={{ ...typography.titleMd, pr: 2, pb: 2 }}>
            {column.header}
          </Typography>
          <span
            style={{ marginRight: "16px" }}
            dangerouslySetInnerHTML={{ __html: cellData?.light_logo }}
          />
          <span dangerouslySetInnerHTML={{ __html: cellData?.dark_logo }} />
          <Typography
            style={{
              ...typography.titleMd,
              display: "block",
              marginTop: "16px",
              textDecoration: "underline",
              color: !isEditMode ? "gray" : "#47BCE5",
              cursor: !isEditMode ? "default" : "pointer",
            }}
            onClick={!isEditMode ? undefined : handleOpenModal}
          >
            {(cellData?.light_logo && cellData?.dark_logo) ? t("TABLE.EDIT_LOGOS") : t("TABLE.UPLOAD_LOGOS")}
          </Typography>

          <UploadLogoModal
            cellData={cellData}
            open={openModal}
            onClose={handleCloseModal}
            onSave={handleSaveLogos}
          />
        </Box>
      );

    case "Boolean":
      // Render Toggle Switches - add headline for expanded row
      return (
        <>
          {isDetailPanel && (
            <Typography variant="bodyMd">{column.header}</Typography>
          )}
          <IOSSwitch
            sx={{ ml: isDetailPanel ? "20px" : "0" }}
            disabled={!isEditMode || !column.isEditable}
            checked={cellData ?? false}
            onChange={(event) => handleChange(event.target.checked)}
          />
        </>
      );

    default:
      return null;
  }
});

export default CellRenderer;
