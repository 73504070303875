import React, { useMemo } from "react";
import { Chip, Card } from "@mui/material";
import { translateTable } from "../../Common";
import {
  common,
  danger,
  neutral,
  primary,
  success,
  warning,
} from "../../theme/colors";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { useTranslation } from "react-i18next";
import { MailSearchResult } from "../../models/MailSearchResult";
import { mailListTableWrapperStyle } from "../../theme/theme";

const tableCellStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "150px",
};

interface MailListProps {
  /**
   * Model data - list of mails
   */
  mailsData: MailSearchResult[];
  /**
   * Currently active selected Mail index
   */
  selectedRowId: string;
  /**
   * Selection callback to parent
   * @param emailId
   */
  onSelectRow: (emailId: string) => void;
}

const AdminMailList: React.FC<MailListProps> = ({
  mailsData,
  selectedRowId,
  onSelectRow
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  const tableBackgroundStyle = {
    backgroundColor: mode === "light" ? common["white"] : neutral[900],
  };

  const getMailStatusColor = (status) => {
    switch (status) {
      case "Unread":
        return primary[500];
      case "Read":
        return neutral[500];
      case "To do":
        return warning[500];
      case "Spam":
        return danger[500];
      case "Done":
        return success[500];
      default:
        return primary[500];
    }
  };

  const columns = useMemo<MRT_ColumnDef<MailSearchResult>[]>(
    () => [
      {
        accessorKey: "from_address",
        header: t("TABLE.FROM"),
        size: 50,
      },
      {
        accessorKey: "timestamp",
        header: t("TABLE.DATE"),
        size: 50,
      },
      {
        accessorKey: "subject",
        header: t("TABLE.SUBJECT"),
        size: 100,
      },
      {
        accessorKey: "read_status",
        header: t("TABLE.STATUS"),
        size: 50,
        Cell: ({ cell }) => (
          <Chip
            label={t("ADMIN.MAILS.STATUS." + cell.getValue())}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: getMailStatusColor(cell.getValue()),
              borderColor: getMailStatusColor(cell.getValue()),
              color: primary[50],
            }}
          />
        ),
      },
    ],
    [t],
  );

  const handleRowClick = (row: any) => {
    const newRowId = selectedRowId === row.id ? null : row.id;
    if (newRowId) {
      const selectedEmail = mailsData.find((mail) => mail.id === newRowId);
      if (selectedEmail) {
        onSelectRow(selectedEmail.id);
      }
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: mailsData,
    initialState: { pagination: { pageSize: 100, pageIndex: 0 }},
    enableGlobalFilter: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      tabIndex: 0, // Make the row focusable
      sx: {
        cursor: "pointer",
        boxShadow: "unset",
            "&:hover, &:focus": {
              backgroundColor: mode === "light" ? primary[200] : primary[800],
              outline: "none",
        },
        backgroundColor:
          selectedRowId.toString() === row.id
            ? mode === "light"
              ? neutral[100]
              : neutral[900]
            : "inherit",
        outline: "none",
      },
    }),
    defaultColumn: { // applies to all columns
      muiTableBodyCellProps: {
        sx: {
          ...tableCellStyles,
        },
      },
      muiTableHeadCellProps: {
        sx: {
          ...tableBackgroundStyle,
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
        ...tableBackgroundStyle,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        ...tableBackgroundStyle,
      },
    },
    muiTopToolbarProps: {
      sx: {
        ...tableBackgroundStyle,
      },
    },
    localization: translateTable(t),
  });

  return (
    <Card sx={mailListTableWrapperStyle}>
      <MaterialReactTable table={table} />
    </Card>
  );
};

export default AdminMailList;
