// Place for common used constants throughout the app

/**
 * Formats UTC string to browsers used locale readable string
 * @param inputDate. UTC string.
 * @param withHour. Display also hour. Default is true
 */
export const formatDate = (inputDate: string, withHour: boolean = true): string => {
  if (!inputDate) {
    return '';
  }

  const date: Date = new Date(inputDate);
  // respect timezone offset
  const clientTimezoneOffsetInMinutes: number = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - clientTimezoneOffsetInMinutes);

  let options: Intl.DateTimeFormatOptions;
  if (withHour) {
    options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  } else {
    options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  }

  // use browser locale
  return date.toLocaleDateString(navigator.language, options);
};

/**
 * Formats UTC string to ISO string
 * @param utcDate. UTC string.
 * @param withoutTime. "YYYY-MM-DD" or "YYYY-MM-DDThh:mm". Default is true.
 */
export const formatUtcDateToIsoString = (utcDate: string, withoutTime: boolean = true): string => {
  if (!utcDate) {
    return '';
  }

  // Create a Date object based explicitly in UTC to avoid timezone discrepancies
  const date = new Date(`${utcDate}Z`); // Append 'Z' to treat it as UTC

  if (withoutTime) {
    // Extract only the date portion
    return date.toISOString().split('T')[0];
  } else {
    // Extract the date with time up to minutes
    return date.toISOString().slice(0, 16);
  }
};

/**
 * Validates if param is a valid email pattern
 * @param email
 */
export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};

/**
 * Regex: Numbers, capital letter, hyphen and whitespace. Max length 10.
 */
export const ZIP_CODE: RegExp = /^[A-Z0-9 -]{0,10}$/;

/**
 * Regex: Numbers, capital letters, hyphen, unlimited length.
 */
export const ALPHA_NUMERIC_HYPHEN: RegExp = /^[A-Z0-9-]+$/;

/**
 * Regex: Numbers, capital letters, unlimited length.
 */
export const ALPHA_NUMERIC: RegExp = /^[A-Z0-9]+$/;

/**
 * Regex: Only numbers with 2 decimal places after "," or "."
 */
export const NUMBERS_WITH_DECIMAL: RegExp = /^\d+([.,]\d{0,2})?$/;

/**
 * Regex: Whole numbers with slashes, hyphens.
 */
export const NUMBERS_WITH_SLASHES_OR_HYPHENS: RegExp = /^\d+([/-]\d+)*$/;
/**
 * Regex: Case sensitive combination of numbers and letters with hyphen, underscore, dot.
 */
export const ALPHANUMERIC_WITH_SPECIAL_CHARS: RegExp = /^[a-zA-Z0-9._-]+$/;

/**
 * Regex: Advanced Email pattern.
 */
export const EMAIL_REGEX: RegExp = /^[^\s@]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/;

/**
 * Hide duration for alerts success
 */
export const AUTO_HIDE_DURATION_SUCCESS: number = 4000;

/**
 * Hide duration for alerts error
 */
export const AUTO_HIDE_DURATION_ERROR: number = 6000;

/**
 * Table component temporary row ID
 */
export const NEW_UNIQUE_ROW_ID: number = 0;

/**
 * Search Mode readonly for Claims
 */
export const SEARCH_TYPE_CLAIM: string = "claims";

/**
 * Search Mode readonly for Mails
 */
export const SEARCH_TYPE_MAILS: string = "mails";

/**
 * Localization for the Material table actions and tooltips.
 * Returns a translated object for the "localization" property.
 *
 * See possible keys here:
 * https://github.com/KevinVandy/material-react-table/blob/v3/packages/material-react-table/src/locales/en.ts
 *
 * @param t - Translation function to use
 */
export const translateTable = (t: Function): {} => {
  return {
    actions: t("TABLE.ACTIONS"),
    expand: t("TABLE.EXPAND"),
    collapse: t("TABLE.COLLAPSE"),
    rowsPerPage: t("TABLE.ROWS_PER_PAGE"),
    goToFirstPage: t("TABLE.GO_TO_FIRST_PAGE"),
    goToLastPage: t("TABLE.GO_TO_LAST_PAGE"),
    goToNextPage: t("TABLE.GO_TO_NEXT_PAGE"),
    goToPreviousPage: t("TABLE.GO_TO_PREVIOUS_PAGE"),
    showHideColumns: t("TABLE.SHOW_HIDE_COLUMNS"),
    showHideFilters: t("TABLE.SHOW_HIDE_FILTERS"),
    showHideSearch: t("TABLE.SHOW_HIDE_SEARCH"),
    sortByColumnAsc: t("TABLE.SORT_BY_COLUMN_ASC"),
    sortByColumnDesc: t("TABLE.SORT_BY_COLUMN_DESC"),
    sortedByColumnAsc: t("TABLE.SORTED_BY_COLUMN_ASC"),
    sortedByColumnDesc: t("TABLE.SORTED_BY_COLUMN_DESC"),
    toggleDensity: t("TABLE.TOGGLE_DENSITY"),
    toggleFullScreen: t("TABLE.TOGGLE_FULL_SCREEN"),
    columnActions: t("TABLE.COLUMN_ACTIONS"),
    clearSort: t("TABLE.CLEAR_SORT"),
    clearFilter: t("TABLE.CLEAR_FILTER"),
    filterByColumn: t("TABLE.FILTER_BY_COLUMN"),
    showAllColumns: t("TABLE.SHOW_ALL_COLUMNS"),
    hideColumn: t("TABLE.HIDE_COLUMN"),
    move: t("TABLE.MOVE"),
    noRecordsToDisplay: t("TABLE.NO_RECORDS_TO_DISPLAY"),
    noResultsFound: t("TABLE.NO_RESULTS_FOUND"),
    search: t("TABLE.SEARCH"),
    clearSearch: t("TABLE.CLEAR_SEARCH"),
    hideAll: t("TABLE.HIDE_ALL"),
    showAll: t("TABLE.SHOW_ALL"),
    of: t("TABLE.OF"),
  };
}

/**
 * Returns the translated item for certain schema keys or the item.
 * @param t - Translation function to use
 * @param accessorKey - column / a schema type
 * @param item - key
 */
export const translate = (t: Function, accessorKey: string, item: string): string => {
  // null as selection is a case
  if (item === null) {
    return item;
  }

  if (accessorKey === "resolution") {
    return t("RESOLVE_MODAL." + item);
  }

  if (accessorKey === "created_by") {
    return t("DASHBOARD.DETAILS." + item);
  }

  if (accessorKey === "recipient_type") {
    return t("DASHBOARD.DETAILS." + item);
  }

  if (accessorKey === "stop_code") {
    return t("RESOLVE_MODAL." + item);
  }

  if (accessorKey === "rejection_code") {
    return t("RESOLVE_MODAL." + item);
  }

  if (accessorKey === "claim_status_id") {
    return t("STATUS." + item);
  }

  if (
      accessorKey === "mail_counterpart" ||
      accessorKey === "mail_topic" ||
      accessorKey === "mail_type"
  ) {
    return t("ADMIN.MAIL_TYPE." + item);
  }

  if (accessorKey === "country" || accessorKey === "recipient_country") {
    return t("ISO." + item.toUpperCase());
  }

  if (accessorKey === "language") {
    return t("LANGUAGE." + item);
  }

  if (
      accessorKey === "damage_currency" ||
      accessorKey === "refund_currency"
  ) {
    return t("CURRENCY." + item);
  }

  if (accessorKey === "category") {
    return t("DASHBOARD.DETAILS." + item);
  }

  return item;
};

/**
 * Returns the number of columns to be shown for the table.
 * @param id
 */
export const getColumnsToShow = (id: string): number => {
  return id === 'claims' ? 8 : 7;
}
