import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import claimBackground from "../assets/gradient.jpg";
import useAuthenticatedFetch from "../auth/authenticated";
import Table from "../components/table/ReactMaterialTable";
import { common } from "../theme/colors";
import NoSearchResults from "../components/claim/NoSearchResults";
import { formatDate, SEARCH_TYPE_CLAIM } from "../Common";
import SearchMailResult from "../components/searchMail/SearchMailResult";
import { MailSearchResult } from "../models/MailSearchResult";

interface SearchProps {
    /**
     * Renders additional action buttons in the Table.
     */
    showExtraActions: boolean;
}

/**
 * Search results limit
 */
const FETCH_LIMIT: number = 10000;

const Search: React.FC<SearchProps> = ({ showExtraActions }) => {
  const location = useLocation();
  const searchValue = new URLSearchParams(location.search).get("q");
  const searchMode = new URLSearchParams(location.search).get("type");

  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  // state hooks
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [searchResult, setSearchResult] = useState<any | MailSearchResult[]>();
  const [claimSchema, setClaimSchema] = useState<any>();

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        fetch(`/api/claims/schema`, {
          method: "GET",
          headers: await getAccessHeader(false),
        }).then((response) => {
          if (!response.ok) {
            console.error("Error: Failed to fetch schema.");
            setError(true);
            setErrorText(t("GENERAL.ERROR"));
          } else {
            response.json().then((schema) => {
              setClaimSchema(schema);
            });
          }
        });
      } catch (error) {
        setError(true);
        setErrorText(t("GENERAL.ERROR"));
      }
    };

    const fetchData = async () => {
      try {
        setSearchResult(undefined);
        setError(false);
        setErrorText("");

        const type: string = searchMode === SEARCH_TYPE_CLAIM ? "claims" : "mails";

        const response = await fetch(
          `/api/${type}/search?search_term=${searchValue}&page_size=${FETCH_LIMIT}`,
          {
            method: "GET",
            headers: await getAccessHeader(false),
          },
        );

        const result = await response.json();

        if (result.error) {
          console.error("Error: Failed to fetch search data.");
          setError(true);
          setErrorText(result.error);

          return;
        }

        // claims search result
        if (searchMode === SEARCH_TYPE_CLAIM && result.hasOwnProperty('entries')) {
            setSearchResult(result.entries);
        } else {
           // mails search result - Apply formatDate to each timestamp to support internal table filter search on date column
           const withFormattedDate: MailSearchResult[] = result.map(item => ({
               ...item,
               timestamp: formatDate(item.timestamp, false),
           }));
           setSearchResult(withFormattedDate);
        }
      } catch (error) {
        setError(true);
        setErrorText(t("GENERAL.ERROR"));
      }
    };

    // fetch search results on search value or search mode change
    fetchData().then();

    // fetch claims schema only once
    if (searchMode === SEARCH_TYPE_CLAIM && claimSchema === undefined) {
      fetchSchema().then();
    }
    // eslint-disable-next-line
  }, [searchValue, searchMode]);

  return (
    <Box sx={{ width: "100%", marginBottom: "96px" }}>
      {/* Header */}
      <Box
        sx={{
          pt: 2,
          pb: 2,
          backgroundImage: `url('${claimBackground}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
        }}
        className="wrapper"
      >
        <Box sx={{ display: "inline-block" }}>
          <Typography
            variant="h1"
            sx={{
              color: common["white"],
              mr: 1,
              display: "inline-block",
            }}
          >
            {t("SEARCH.RESULTS_FOR")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              color: common["white"],
              mr: 1,
              display: "inline-block",
            }}
          >
            {searchValue}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: common["white"],
              display: "inline-block",
            }}
          >
            {searchMode === SEARCH_TYPE_CLAIM
              ? t("SEARCH.IN_CLAIMS")
              : t("SEARCH.IN_MAILS")}
          </Typography>
        </Box>
      </Box>

      {/* Error / Loading Feedback */}
      <>
        {error ? (
          <Box sx={{ pt: 3, m: 5, textAlign: "center" }}>{errorText}</Box>
        ) : (searchMode === SEARCH_TYPE_CLAIM && claimSchema === undefined) ||
          searchResult === undefined ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              mt: "100px",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Search Results */}
            {searchResult.length === 0 && searchMode ? (
              <NoSearchResults searchMode={searchMode}></NoSearchResults>
            ) : (
              <Box sx={{ pt: 3 }} className="wrapper">
                {searchMode === SEARCH_TYPE_CLAIM ? (
                  <Table
                    initialData={searchResult}
                    columnData={claimSchema}
                    endpoint={"/api/claims"}
                    showCreateNewButton={false}
                    showExtraActions={showExtraActions}
                    id={"claims"}
                  ></Table>
                ) : (
                  <SearchMailResult mailsData={searchResult}></SearchMailResult>
                )}
              </Box>
            )}
          </>
        )}
      </>
    </Box>
  );
};

export default Search;
