import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import { NEW_UNIQUE_ROW_ID, translateTable } from "../../Common";
import { ReactComponent as AddIcon } from "../../assets/table/add.svg";
import { ReactComponent as CloseIcon } from "../../assets/table/close.svg";
import { ReactComponent as DeleteIcon } from "../../assets/table/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/table/edit.svg";
import { ReactComponent as LaunchIcon } from "../../assets/table/external-link.svg";
import { ReactComponent as SaveIcon } from "../../assets/table/save.svg";
import { ReactComponent as CopyIdIcon } from "../../assets/idCopy.svg";
import useAuthenticatedFetch from "../../auth/authenticated";
import { common, neutral } from "../../theme/colors";
import { FEEDBACK } from "../../models/Feedback";
import CellRenderer from "./CellRenderer";
import { generateColumns } from "./GenerateColumns";
import handleCancelRow from "./handleCancelRow";
import handleCreateRow from "./handleCreateRow";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import Feedback from "../ui/Feedback";
import { RowData, TableColumn } from "../../models/Table";
import DetailPanel from "./DetailPanel";

const Table = ({
  initialData,
  columnData,
  endpoint,
  showCreateNewButton,
  showExtraActions,
  id,
}) => {
  const getAccessHeader = useAuthenticatedFetch();
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const tableBackgroundStyle = {
    backgroundColor: mode === "light" ? common["white"] : neutral[900],
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [flattenedData, setFlattenedData] = useState<any>([]);
  const [initialFlattenedData, setInitialFlattenedData] = useState<any>([]);
  const [editableRowId, setEditableRowId] = useState<number | null>(null);
  const [editedFields, setEditedFields] = useState({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});
  const [detailColumns, setDetailColumns] = useState<TableColumn[]>([]);

  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const [customErrorMessage, setCustomErrorMessage] = useState<string | undefined>(undefined);
  const [copied, setCopied] = useState(false);

  /**
   * Generate the initial rows data once
   */
  useEffect(() => {
    // Flatten the unpleasant API response structure of each object in the array
    if (initialData.length > 0) {
      // Flatten the data by iterating over each item in the initialData array
      const flattenedData = initialData.map((item) => {
        return flatItem(item);
      });

      // save as stable reference in state
      setFlattenedData(flattenedData);
      // Set the initial flattened data state to the same array for resetting purposes after editing
      setInitialFlattenedData(flattenedData);
    }
  }, [initialData]);

  /**
   * Maps the Backend data to a flattened object
   * @param item
   */
  const flatItem = (item: RowData) : any => {
    const flattenedItem: {} = {};

    // Copy all properties from item.entry to the flattenedItem object
    for (const key in item.entry) {
      flattenedItem[key] = item.entry[key];
    }

    // Copy all properties from item.on_delete_info to the flattenedItem object
    for (const key in item.on_delete_info) {
      flattenedItem[key] = item.on_delete_info[key];
    }

    return flattenedItem;
  }

  /**
   * Updates the claim data object with the complete data properties
   * @param response
   * @param rowIndex
   */
  const addCompleteClaimData = (response, rowIndex) => {
    const flattenedClaim = flatItem(response.entries[0]);
    // add indicator to prevent api call again
    flattenedClaim.claimDetailsLoaded = true;

    setFlattenedData((prevData) => {
        const newData = [...prevData];
        // Update the specific row with a new object holding the detail values
        newData[rowIndex] = flattenedClaim;

        return newData;
    });

    // Also update the initial data for resetting purposes after editing
    setInitialFlattenedData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = flattenedClaim;

      return newData;
    });
  }

  /**
   * Requests all fields of a claim from API and updates the existing data
   * @param row
   */
  const fetchClaimDetailData = async (row) => {
    if (id === 'claims' && !row.getIsExpanded()) {
      // prevent duplicate api call when detail data for claim row was already fetched
      if (flattenedData[row.id].claimDetailsLoaded) {
        return;
      }

      const response = await fetch('/api/claims?filter=id:' + row.original.id, {
        method: 'GET',
        headers: await getAccessHeader(false),
      });

      if (!response.ok) {
        console.error("Error fetching claim details");
        return;
      }

      const data = await response.json();
      addCompleteClaimData(data, row.id);
    }
  }

  const handleEditRowClick = (row) => {
    // load detail data of claims
    if (id === 'claims') {
      fetchClaimDetailData(row).then();
    }

    // Set the row being edited by updating the editableRowId state
    setEditableRowId(row.original.id);

    // Expand the row in the table to show its details
    table.setExpanded({ [row.id]: true });
  };

  const handleCancelRowClick = (row) => {
    setFieldErrors({});

    handleCancelRow(
        row,
        initialFlattenedData,
        setFlattenedData,
        setEditableRowId,
        setEditedFields,
        table,
     ).then();
  };

  const handleCreateRowClick = () => {
    setFieldErrors({});

    handleCreateRow(
      setFlattenedData,
      setEditableRowId,
      columns,
      table,
    );
  };

  const validateRow = (row) => {
    const errors: { [key: string]: boolean } = {};

    columns.forEach((column) => {
      // Check if the field is non-nullable and empty
      if (
        !column.nullable &&
        column.type !== "Boolean" &&
        !row.original[column.accessorKey]
      ) {
        errors[column.accessorKey] = true;
      }
    });

    setFieldErrors(errors);

    return errors;
  };

  const handleSaveRowClick = async (row) => {
    const invalidColumns = validateRow(row);

    if (Object.keys(invalidColumns).length > 0) {
      setShowFeedback(FEEDBACK.ERROR);
      setCustomErrorMessage(t("GENERAL.ERROR_VALIDATION"));
      console.log("Validation errors:", invalidColumns);

      return;
    }

    setCustomErrorMessage(undefined);

    // update data model
    try {
      let response;
      // Row is being edited, perform PUT request
      if (row.original.id !== NEW_UNIQUE_ROW_ID) {
        // Include the id field from the original row along with editedFields
        const editedFieldsWithId = { ...editedFields, id: row.original.id };

        const url = `${endpoint}/${row.original.id}`;

        response = await fetch(url, {
          method: "PUT",
          headers: await getAccessHeader(),
          body: JSON.stringify(editedFieldsWithId),
        });

        const result = await response.json();

        if (result.success) {
          // Update initial data and empty edited fields state
          setInitialFlattenedData(flattenedData);
          setEditedFields({});
          setShowFeedback(FEEDBACK.SUCCESS);
        } else {
          setShowFeedback(FEEDBACK.ERROR);
          setCustomErrorMessage("Failed to save: " + result.error);
          console.error("Failed to save row:", result.error);
          // close opened edited row to get old values back into view
          handleCancelRowClick(row);
        }
      } else {
        // New row, perform POST request
        response = await fetch(endpoint, {
          method: "POST",
          headers: await getAccessHeader(),
          body: JSON.stringify(editedFields),
        });

        const result = await response.json();

        if (result.success) {
          row.original.id = result.id;
          setShowFeedback(FEEDBACK.SUCCESS);
          setEditedFields({});
        } else {
          setShowFeedback(FEEDBACK.ERROR);
          setCustomErrorMessage("Failed to save: " + result.error);
          console.error("Failed to save row:", result.error);
          // remove temp new row from view
          handleCancelRowClick(row);
        }
      }

      // reset and close
      table.setCreatingRow(null);
      setEditableRowId(null);
      table.setExpanded({ [row.id]: false });
    } catch (error) {
      console.error("Error saving row:", error);
    }
  };

  // update data model
  const handleDeleteRowClick = (row) => {
    handleDeleteRow(
      row.original.id,
      endpoint,
      setFlattenedData,
    ).then(() => setInitialFlattenedData(flattenedData));
  };

  const handleDeleteRow = async (rowId, endpoint, setFlattenedData) => {
    try {
      const response = await fetch(`${endpoint}/${rowId}`, {
        method: "DELETE",
        headers: await getAccessHeader(false),
      });

      const result = await response.json();

      if (result.success) {
        setFlattenedData((prevRowData) =>
          prevRowData.filter((row) => row.id !== rowId),
        );

        setShowFeedback(FEEDBACK.SUCCESS);
      } else {
        setShowFeedback(FEEDBACK.ERROR);
        setCustomErrorMessage("Failed to delete: " + result.error);
        console.error(`Failed to delete row with ID ${rowId}.`);
        return;
      }
    } catch (error) {
      setShowFeedback(FEEDBACK.ERROR);
      console.error("Error deleting row:", error);
    }
  };

  const handleDragEnd =
    (
      table,
      flattenedData,
      setFlattenedData,
      endpoint,
    ) =>
    async () => {
      const { draggingRow, hoveredRow } = table.getState();

      if (hoveredRow && draggingRow) {
        const newFlattenedData = [...flattenedData];
        const draggedRow = newFlattenedData.splice(draggingRow.index, 1)[0];
        newFlattenedData.splice(hoveredRow.index, 0, draggedRow);

        try {
          const response = await fetch(
            `${endpoint}/${hoveredRow.original.id}`,
            {
              method: "PUT",
              headers: await getAccessHeader(),
              body: JSON.stringify({
                order_number: hoveredRow.index,
                id: draggedRow.id,
              }),
            },
          );

          const result = await response.json();

          if (result.success) {
            setFlattenedData(newFlattenedData);
            setShowFeedback(FEEDBACK.SUCCESS);
          } else {
            setShowFeedback(FEEDBACK.ERROR);
            setCustomErrorMessage("Failed to save new order: " + result.error);
            console.error("Failed to save new order", result.error);
          }
        } catch (error) {
          setShowFeedback(FEEDBACK.ERROR);
          console.error(`Error updating row order number: ${error}`);
        }
      }
    };

  const handleOpenClaimView = (row) => {
    window.open("/claims/" + row.original.id, "_blank");
  };

  const handleCopyClaimId = (row) => {
    navigator.clipboard.writeText(row.original.id).then();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  /**
   * Only used on mail templates table. Visualizes the enabled status.
   * @param row
   */
  const renderEnabledStatus = (row) => {
    const enabled: boolean = row.original.enabled;
    return (
      <Tooltip title={t(enabled ? "TABLE.ACTIVE" : "TABLE.INACTIVE")}>
        <Chip
          color={enabled ? "success" : "error"}
          size="small"
          sx={{ marginTop: "12px", marginRight: "8px", display: "block", height: "16px" }}
        />
      </Tooltip>
    );
  };

  const handleCellValueChange = (rowIndex: number, columnName: string, value: any) => {
    // reset an eventual error state since the column (cell) has now at least a value
    if (columnName in fieldErrors) {
      const updatedErrors = { ...fieldErrors };
      delete updatedErrors[columnName];

      setFieldErrors(updatedErrors);
    }

    // add neutral timezone to match display from BE data
    if (columnName === 'wakeup_time') {
      value += 'T00:00:00';
    }

    setEditedFields((prevState) => ({
      ...prevState,
      [columnName]: value,
    }));

    // Update flattenedData to display input changes
    setFlattenedData((prevData) => {
        const newData = [...prevData];
        // Update the specific row property by creating a new object with the updated value
        newData[rowIndex] = {
          ...newData[rowIndex],
          [columnName]: value,
        };

        return newData;
    });
  };

  // Define the initial table state - like page size and other properties
  const initialStateOptions = {
    columnVisibility: {},
    pagination: { pageSize: 100, pageIndex: 0 },
  };

  // Generate columns based on BE structure and memoize it to improve performance
  const columns = useMemo(() => {
    const columnsToExclude = ["order_number", "id"];
    const result: TableColumn[] = generateColumns(columnData, columnsToExclude, t, id);

    // columns that have to be hidden
    const detailColumns: TableColumn[] = [];
    result.forEach((column) => {
      if (column.renderColumn === false) {
        initialStateOptions.columnVisibility[column.accessorKey] = false;
        detailColumns.push(column);
      }
    });
    setDetailColumns(detailColumns);

    return result;
  }, []);

  // Mapping columns and adding a custom Cell renderer for each column (expensive operation for huge data sets)
  const memoizedColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      Cell: ({ row }) => (
          <CellRenderer
              fieldErrors={fieldErrors}
              rowIndex={row.id}
              cellData={row.original[column.accessorKey]}
              column={column}
              isEditMode={editableRowId === row.original.id}
              isDetailPanel={false}
              onCellValueChange={handleCellValueChange}
          />
      ),
    }));
  }, [editableRowId, fieldErrors]);


  const table = useMaterialReactTable({
    // Custom representation for the table columns
    columns: memoizedColumns,
    // Data for the table rows
    data: flattenedData,
    // Initial state options like sorting, pagination, etc.
    initialState: initialStateOptions,
    // Enable drag and drop ordering only for the mails & settings table
    enableRowOrdering: id === "mails" || id === "settings",
    // Disable sorting for mail templates to avoid interfering with drag & drop ordering
    enableSorting: id !== "mails",
    enableFilters: id !== "mails",  // Disable filters for mail templates
    enableGlobalFilter: false,      // Disable global search filtering
    enableRowNumbers: true,         // Enable row numbers for the table
    rowNumberDisplayMode: "original",  // Show the original order of rows
    createDisplayMode: "row",       // Row-based display mode for new row creation
    editDisplayMode: "row",         // Row-based display mode for editing
    enableEditing: true,            // Enable editing functionality in the table
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",         // Set minimum table height
        ...tableBackgroundStyle,    // Apply custom table background style
      },
    },
    defaultColumn: { // applies to all columns
      muiTableHeadCellProps: {
        sx: {
          ...tableBackgroundStyle,    // Apply custom table background style to header
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        ...tableBackgroundStyle,    // Fix for the "Expand" column only: background color is not override via "defaultColumn" props - let´s hope the next material version will fix this issue
      },
    },
    muiBottomToolbarProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to bottom toolbar
      },
    },
    muiTopToolbarProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to top toolbar
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to body rows
        boxShadow: "unset",         // Remove row box shadow
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "1.1px 2.7px 3.8px -1.2px #BBBBBB42"  // Custom box shadow for the table
      }
    },
    enablePagination: true,          // Enable pagination for table rows
    autoResetPageIndex: false,       // Disable auto-reset of page index on changes
    onColumnFiltersChange: setColumnFilters, // Function to handle filter changes
    onSortingChange: setSorting,     // Function to handle sorting changes
    enableStickyHeader: true,        // Keep table header visible while scrolling through the table

    // Render row actions (edit, delete, etc.) conditionally based on the row being edited
    renderRowActions: ({ row }) => (
        <Box sx={{ display: "flex" }}>
          {editableRowId === row.original.id ? (
              <>
                <Tooltip title={t("GENERAL.CANCEL")}>
                  <IconButton onClick={() => handleCancelRowClick(row)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("GENERAL.SAVE")}>
                  <IconButton onClick={() => handleSaveRowClick(row)}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
          ) : (
              <>
                {showExtraActions && (
                    <>
                      <Tooltip title={t("TABLE.OPEN_CLAIM_VIEW")}>
                        <IconButton onClick={() => handleOpenClaimView(row)}>
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                          title={
                            copied
                                ? t("DASHBOARD.INPUT_COPIED")
                                : t("TABLE.COPY_CLAIM_ID")
                          }
                      >
                        <IconButton onClick={() => handleCopyClaimId(row)}>
                          <CopyIdIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                )}

                {/* Mails Table shows a visual enabled status indicator */}
                {id === 'mails' && (renderEnabledStatus(row))}

                <Tooltip title={t("GENERAL.EDIT")}>
                  <IconButton onClick={() => handleEditRowClick(row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                {/* Do not show delete button for Claims Table */}
                {id !== 'claims' &&
                  <Tooltip title={!row.original.is_deletable ? t("TABLE.NOT_DELETABLE_INFO") : t("GENERAL.DELETE")}>
                  <span>
                    <IconButton
                        disabled={!row.original.is_deletable}
                        onClick={() => handleDeleteRowClick(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                  </Tooltip>
                }
              </>
          )}
        </Box>
    ),

    // Position the actions column at the end of the table
    positionActionsColumn: "last",

    // Render custom toolbar actions (like adding a new row)
    renderTopToolbarCustomActions: () =>
        showCreateNewButton ? (
            <Button
                color="primary"
                startIcon={<AddIcon />}
                disabled={editableRowId === NEW_UNIQUE_ROW_ID}  // Disable the button if a new row is being created
                onClick={() => handleCreateRowClick()}
            >
              {t("ADMIN.BUTTON_ADD_NEW")}
            </Button>
        ) : null,

    // Disable expand all functionality
    enableExpandAll: false,

    // Position expand/collapse icons at the last column
    positionExpandColumn: "last",

    // Render a detail panel when a row gets expanded for view or editing.
    // Note: If table data columns amount is enough, the "expand row" button gets rendered as disabled due to null.
    renderDetailPanel: ({ row }) =>
        detailColumns.length > 0 ?
            <DetailPanel
                row={row}
                columns={detailColumns}
                isEditMode={editableRowId === row.original.id}
                fieldErrors={fieldErrors}
                onCellValueChange={handleCellValueChange}
            /> : null,
    // Handle click on expand button and load details data (for claims only)
    muiExpandButtonProps: ({row}) => ({
       onClick: () => fetchClaimDetailData(row).then()
    }),
    // Handle row dragging for ordering purposes
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: handleDragEnd(
          table,
          flattenedData,
          setFlattenedData,
          endpoint,
      ),
    }),

    // Define the state for filters and sorting
    state: {
      columnFilters,
      sorting,
    },

    // Localization for the table actions and tooltips
    localization: translateTable(t),
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
      {/* Request Feedback */}
      <Feedback type={showFeedback} onClose={setShowFeedback} errorMessage={customErrorMessage}></Feedback>
    </Box>
  );
};

export default Table;
